

























































import {
  Component, Prop, Vue, Inject,
} from 'vue-property-decorator';

import moment from 'moment';

import Checkbox from '@/components/Input/Checkbox/index.vue';
// import ModalCreditsWarning from '@/components/Modal/ModalCreditsWarning/index.vue';

// import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import ActiveModal from '@/share/Util/ActiveModal';

import { FileVideo } from '../../interface';

const THUMB_VIDEO_DEFAULT = 'https://i.vimeocdn.com/video/1179345692-ddf06d2d7fe786ef4e08492bca5487efcb9e1a18b2517d2b45dc00025662317f-d_200x150?r=pad';

@Component({
  components: {
    Checkbox,
    // ModalCreditsWarning,
  },
})
export default class VideoListItem extends Vue {
  @Prop() file!: FileVideo;
  @Prop() current!: boolean;
  @Prop() checked!: boolean;
  @Prop() currentChecked!: boolean;

  @Inject('checked-video') private injectCheckedVideo!: Function;

  private setModal = new ActiveModal();

  private show = false;

  // get isPlanFree() {
  //   return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  // }

  get currentIdVideo() {
    return this.file.idVideo;
  }

  get videoClicked() {
    return this.$store.getters.videoClicked;
  }

  get isPermission() {
    // if (!this.isPlanFree) return this.file?.isPermission;

    // const { is_credits_video } = this.$store.getters.creditData;

    // if (is_credits_video) return true;

    // return this.checkVideoHasBeenWatched(this.currentIdVideo);
    return this.file?.isPermission;
  }

  setTime() {
    const formatted = moment.utc(this.file?.time * 1000).format('HH:mm:ss');
    return formatted;
  }

  getImage() {
    const image = this.file?.detail?.thumb
      && this.file.detail.thumb[1]
      && this.file.detail.thumb[1].link
      ? this.file.detail.thumb[1].link
      : THUMB_VIDEO_DEFAULT;
    return image;
  }

  handleContentClick() {
    if (this.file.idVideo === Number(this.$route.params.fileID)) return;

    if (!this.file.isPermission) {
      // if (this.isPlanFree) this.showModalCredits();
      // else this.showModalPermission();

      this.showModalPermission();

      return;
    }

    this.saveClickedVideoToStore();

    // if (this.isPlanFree) {
    //   this.handleWithFreePlan();
    //   return;
    // }

    this.changePath();
  }

  saveClickedVideoToStore() {
    this.$store.commit('setVideoClicked', this.file);
  }

  // handleWithFreePlan() {
  //   const { is_credits_video } = this.$store.getters.creditData;

  //   if (is_credits_video) {
  //     this.handleWithFreePlanWhenHasCredit();
  //     return;
  //   }

  //   this.handleWithFreePlanWhenNoCredit();
  // }

  // handleWithFreePlanWhenHasCredit() {
  //   const videoAlreadySeenThisMonth = this.checkVideoHasBeenWatched();

  //   if (!videoAlreadySeenThisMonth) {
  //     this.showModalCreditsWarning();
  //     return;
  //   }

  //   this.changePath();
  // }

  // handleWithFreePlanWhenNoCredit() {
  //   const videoAlreadySeenThisMonth = this.checkVideoHasBeenWatched();

  //   if (!videoAlreadySeenThisMonth) {
  //     this.showModalCredits();
  //     return;
  //   }

  //   this.changePath();
  // }

  // checkVideoHasBeenWatched(currentVideoId?: number): boolean {
  //   const { idsVideoWatched, recentlyViewedVideo } = this.$store.getters;
  //   const { idVideo } = this.videoClicked;

  //   const videoIsInEndpointList = idsVideoWatched.length
  //     ? idsVideoWatched.includes(currentVideoId || idVideo) : false;

  //   const videoIsRecentlyViewedVideo = recentlyViewedVideo.length
  //     ? recentlyViewedVideo.includes(currentVideoId || idVideo) : false;

  //   return videoIsInEndpointList || videoIsRecentlyViewedVideo;
  // }

  changePath() {
    if (this.videoClicked?.idVideo === Number(this.$route.params.fileID)) {
      return;
    }

    this.$emit('change-path-video', this.videoClicked);
  }

  setCheckbox(type: string, checked: boolean) {
    this.show = !checked;
    this.injectCheckedVideo(this.file, this.show);
  }

  showModalPermission() {
    this.setModal.activeModal('modalNotContentPlan');
  }

  // showModalCredits() {
  //   this.setModal.activeModal('modalCredits');
  // }

  // showModalCreditsWarning() {
  //   this.setModal.activeModal('modalCreditsWarning');
  // }
}
